<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>新增付款记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">

      <el-row :gutter="30" style="margin-bottom: 30px;">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <span class="title">退货订单号：</span>
          <span class="text">{{addPurchaseForm.sr_code}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">订单金额：</span>
          <span class="text">{{addPurchaseForm.sr_price}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">已付款金额：</span>
          <span class="text">{{addPurchaseForm.sr_payment_price}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">未付款金额：</span>
          <span class="text">{{addPurchaseForm.sr_unpaid_price}}</span>
        </el-col>
      </el-row>
      <el-form  :model="addPurchasePayForm" :rules="addPurchasePayFormRules"
                ref="addPurchasePayFormRef"
                label-width="100px">
        <el-form-item label="付款金额：" prop="p_payment_price">
          <el-input v-model="addPurchasePayForm.p_payment_price" class="sp_payment_price" @blur="price"
                    @keyup.native="addPurchasePayForm.p_payment_price = money(addPurchasePayForm.p_payment_price)"></el-input>
        </el-form-item>
        <el-form-item label="付款日期：" prop="p_payment_date">
          <!--          <el-input v-model="addPurchaseForm.pp_payment_date" class="sp_payment_price"></el-input>-->
          <el-date-picker
              v-model="addPurchasePayForm.p_payment_date"
              type="datetime" value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付方式：" prop="p_payment_method">
          <!--          <el-input v-model="addPurchaseForm.pp_payment_method" class="sp_payment_price" disabled></el-input>-->
          <el-select v-model="addPurchasePayForm.p_payment_method"
                     placeholder="请选择" class="sp_payment_method"
                     @change="selectpay(addPurchasePayForm.p_payment_method)">
            <el-option
                v-for="item in List"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户名称：" prop="p_account_name" v-if="addPurchasePayForm.p_payment_method==2">
          <el-input v-model="addPurchasePayForm.sp_account_name" class="sp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="开户银行：" prop="p_account_bank" v-if="addPurchasePayForm.p_payment_method==2">
          <el-input v-model="addPurchasePayForm.sp_account_bank" class="sp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="银行账号：" prop="p_bank_account" v-if="addPurchasePayForm.p_payment_method==2">
          <el-input v-model="addPurchasePayForm.sp_bank_account" class="sp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="p_remark">
          <el-input v-model="addPurchasePayForm.p_remark" class="sp_payment_price" ></el-input>
        </el-form-item>
        <el-form-item label="支付凭证：" prop="pp_payment_img">
          <!--          <el-input v-model="addPurchasePayForm.pp_payment_img" class="sp_payment_price" ></el-input>-->
<!--          <el-upload class="avatar-uploader" :headers="headersObj"-->
<!--                     :action="action" :show-file-list="false"-->
<!--                     :on-success="edithandleAvatarSuccess" >-->
<!--            <div v-if="addPurchasePayForm.img" class="upload-avatar">-->
<!--              <img  :src="addPurchasePayForm.img">-->
<!--            </div>-->
<!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--          </el-upload>-->
          <el-upload class="avatar-uploader" :headers="headersObj"
                     :action="action" :show-file-list="false"
                     :on-success="edithandleAvatarSuccess" >
            <div v-if="addPurchasePayForm.p_payment_img_see" class="upload-avatar">
              <img  :src="addPurchasePayForm.p_payment_img_see">
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!--          <el-button type="danger" v-if="addCateForm.c_icon" size="mini" class="upload-delete" @click="handleRemove">删除</el-button>-->
          <div  v-if="addPurchasePayForm.p_payment_img_see" class="upload-delete" @click="handleRemove">
            <i class="el-icon-close"></i>
          </div>
        </el-form-item>
        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>

    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return{
      addloading:false,
      addPurchaseForm:{},
      List:[
        {id:1,name:'现金'},
        {id:2,name:'银行转账'},
        {id:3,name:'微信'},
        {id:4,name:'支付宝'},
      ],
      addPurchasePayForm:{
        sr_id: this.$route.query.editid,
        sp_payment_img:'',
        img:'',
        p_payment_date:this.$store.state.daydate
      },
      addPurchasePayFormRules:{
        p_payment_price:[
          {required: true, message: '请输入付款金额', trigger: 'blur'}
        ],
        p_payment_date:[
          {required: true, message: '请选择付款日期', trigger: 'change'}
        ],
        p_payment_method:[
          {required: true, message: '请选择支付方式', trigger: 'change'}
        ]

      },
      headersObj:{
        Authorization:'Bearer ' + window.sessionStorage.getItem('token')
      },
      action:process.env.VUE_APP_BASE_API+'/upload/category',


    }
  },
  methods: {
    //获取采购订单数据
    async getpurchaseList() {
      const {data: res} = await this.$http.get(`salereturn/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addPurchaseForm = res.data
      // this.querInfo.s_id=res.data.s_id
      // this.getGoodsList(this.querInfo)
      // this.adddisabled=false

    },
    addgo(){
      this.$router.go(-1)
    },
    //提交form表单
    async addupPurchase(){
      // console.log(this.addPurchasePayForm)
      this.$refs.addPurchasePayFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('salereturn/payment', this.addPurchasePayForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    //监听图片上传成功的事件
    edithandleAvatarSuccess(response){
      // this.addPurchasePayForm.p_payment_img=response.data.saveurl
      // this.addPurchasePayForm.img=response.data.saveurl
      this.addPurchasePayForm.p_payment_img=response.data.saveurl
      this.addPurchasePayForm.p_payment_img_see=response.data.url
    },
    //添加分类的图片删除
    handleRemove(){
      this.addPurchasePayForm.p_payment_img=''
      this.addPurchasePayForm.p_payment_img_see=''
    },
    //验证价格
    price(){
      if (isNaN(this.addPurchasePayForm.sp_payment_price)==true){
        this.addPurchasePayForm.sp_payment_price=0.00
      }else if(Number(this.addPurchasePayForm.sp_payment_price)<=0){
        this.addPurchasePayForm.sp_payment_price=0.00
      }else if(Number(this.addPurchasePayForm.sp_payment_price)>Number(this.addPurchaseForm.s_unpaid_price)){
        this.addPurchasePayForm.sp_payment_price=Number(this.addPurchaseForm.s_unpaid_price)
      }else if(Number(this.addPurchasePayForm.sp_payment_price)>0){
        // console.log("123456789")
        this.addPurchasePayForm.sp_payment_price=Number(this.addPurchasePayForm.sp_payment_price).toFixed(2)
      }
    },
    //判断支付方式
    selectpay(id){
      if(id==2){
        this.addPurchasePayForm.p_account_name=this.addPurchaseForm.c_account_name
        this.addPurchasePayForm.p_account_bank=this.addPurchaseForm.c_account_bank
        this.addPurchasePayForm.p_bank_account=this.addPurchaseForm.c_bank_account
      }else{
        this.addPurchasePayForm.p_account_name=''
        this.addPurchasePayForm.p_account_bank=''
        this.addPurchasePayForm.p_bank_account=''
      }
    }
  },
  created(){
    this.getpurchaseList()
  }
}
</script>
<style lang="less" scoped>
.el-col{
  display: flex;
}
.pirce{
  width: 200px;
}
.title{
  color: #415161;
  font-size: 13px;
}
.text{
  color: #415161;
  font-size: 13px;
}
.el-input{
  width: 280px;
}
.sp_payment_method{
  width: 280px;
}
</style>
